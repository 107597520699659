import React, { useEffect, useState } from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

import '../../../css/mainPage/component13.css';
import window1 from '../../../assest/svgEl/main/window1-c13.svg';
import window2 from '../../../assest/svgEl/main/window2-c13.svg';
import logo from '../../../assest/svgEl/logo.svg';
import { Link } from 'react-router-dom';
import Stack from "@mui/material/Stack";

const Component13 = ({ handleToggleContactForm }) => {
  const apiKey = 'cb14e072-ef48-4930-9ea8-fc8542e8a82a';
  const locations = [
    {
      coordinates: [52.643226, 39.629906],
      name: 'Schtern',
      description: '<a href="https://ok-schtern.ru/" target="_blank">Ссылка на сайт</a>',
      phoneNumber: '8 800 550-58-78'
    }
    // Другие местоположения
  ];

  const [center, setCenter] = useState([52.643226, 39.629906]); // Значение по умолчанию

  useEffect(() => {
    // Получение геолокации пользователя
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setCenter([latitude, longitude]); // Установка полученных координат в качестве центра карты
      });
    } else {
      console.log('Геолокация недоступна на этом устройстве.');
    }
  }, []);

  const [activePlacemark, setActivePlacemark] = useState(null);

  const test = (location) => {
    setActivePlacemark(location);
  };

  return (
    <div className="component13">



      <div className="windows-c13">
        <img src={window1} alt="window1" />
        <img src={window2} alt="window1" />
      </div>
      <div className="logo-c13">
        <img src={logo} alt="logo" className="logo-c13-img" />
      </div>

      <div class="text-block1-c13">Адрес:</div>
      <div class="text-block2-c13">Телефон:</div>
      <div class="text-block3-c13">
        г. Липецк, <br />
        ул. Римского-Корсакова 10А
      </div>
      <div class="text-block4-c13">
        <a href="tel:+78005505878">8 800 550-58-78</a>
        <a href="tel:+74742242924">+7 (4742) 24-29-24</a>
      </div>

      <div class="text-block5-c13">Почта:</div>
      <div class="text-block6-c13">График работы:</div>
      <div class="text-block7-c13">shop@ok-schtern.ru</div>
      <div class="text-block8-c13">
        Пн. – Пт.:
        <br />с 8:00 до 17:00
          <br />Обеденный перерыв
          <br />с 12:00 до 13:00
      </div>

      <div className="maps-c13" >
        <p>Где купить?</p>
          <Stack sx={{
              borderRadius: 1
          }}>
        <YMaps apiKey={apiKey}>
          <div >
            <Map
              defaultState={{ center: [52.643226, 39.629906], zoom: 10 }}
              className="block-map-c13">
                {locations.map((location, index) => (
                <Placemark
                  key={index}
                  geometry={location.coordinates}
                  properties={{
                    iconCaption: location.name,
                    balloonContentHeader: location.name,
                    balloonContentBody: `
      <b>Сайт:</b> <a href=${location.description} </a><br>
      <b>Номер телефона:</b> ${location.phoneNumber}`
                  }}
                  options={
                    {
                      // Здесь можно настроить стиль иконки метки
                    }
                  }
                  onClick={() => {
                    test(location);
                  }}
                />
              ))}

              {activePlacemark && (
                <Placemark
                  geometry={activePlacemark.coordinates}
                  properties={{
                    balloonContentHeader: activePlacemark.name,
                    balloonContentBody: activePlacemark.description
                  }}
                  onClose={() => {
                    setActivePlacemark(null);
                  }}
                  modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                />
              )}
            </Map>
          </div>
        </YMaps>
          </Stack>
        <div class="table-container">
          <div class="grid-table">
            <Link to={'/plastikovye-okna'}>
              <div class="grid-cell">Пластиковые окна</div>
            </Link>
            <div class="grid-cell">Готовые пластиковые окна</div>
            <div class="grid-cell">Калькулятор окон</div>
            <div class="grid-cell">Окна в рассрочку</div>
            <Link to={'/o-kompanii'}>
              <div class="grid-cell">О компании</div>
            </Link>
            <div class="grid-cell" onClick={handleToggleContactForm}>
              Заказать бесплатный замер
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component13;
