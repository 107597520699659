const menu = [
  {
    name: 'Пластиковые окна',
    link: '/plastikovye-okna',
    // array: [
    //   { name: 'Пластиковые окна', array: [], link: '/plastikovye-okna' },
    //   // { name: 'Раздвижные окна', array: [], link: '/not-found' },
    //   // {
    //   //   name: 'Нестандартные окна',
    //   //   array: [
    //   //     { name: 'Панорамные окна', array: [], link: '/not-found' },
    //   //     { name: 'Арочные окна', array: [], link: '/not-found' },
    //   //     { name: 'Эркерные окна', array: [], link: '/not-found' },
    //   //     { name: 'Бронированные окна', array: [], link: '/not-found' },
    //   //     { name: 'Треугольные окна', array: [], link: '/not-found' },
    //   //     { name: 'Окна нестандартной формы', array: [], link: '/not-found' },
    //   //     { name: 'Круглые окна', array: [], link: '/not-found' }
    //   //   ]
    //   // },
    //   // {
    //   //   name: 'Нестандартные цвет',
    //   //   array: [
    //   //     { name: 'Цветные пластиковые окна', array: [], link: '/not-found' },
    //   //     { name: 'Пластиковые окна под дерево', array: [], link: '/not-found' }
    //   //   ]
    //   // },
    //   // {
    //   //   name: 'Другие категории',
    //   //   array: [
    //   //     { name: 'Тонированные окна', array: [], link: '/not-found' },
    //   //     { name: 'Шумоизоляционные окна', array: [], link: '/not-found' },
    //   //     { name: 'Солнцезащитные окна', array: [], link: '/not-found' },
    //   //     { name: 'Окна со шпросами', array: [], link: '/not-found' },
    //   //     { name: 'Межкомнатные окна', array: [], link: '/not-found' }
    //   //   ]
    //   // },
    //   {
    //     name: 'По типу конструкции',
    //     array: [
    //       { name: 'Одностворчатые окна', array: [], link: '/odnostvorchatye-okna' },
    //       { name: 'Двухстворчатые окна', array: [], link: '/dvustvorchatye-okna' },
    //       { name: 'Трехстворчатые окна', array: [], link: '/trehostvorchatye-okna' }
    //     ]
    //   },
    //   {
    //     name: 'По уровню тепла',
    //     array: [
    //       { name: 'Однокамерные стеклопакеты', array: [], link: '/odnokamernye-steklopakety' },
    //       { name: 'Двухкамерные стеклопакеты', array: [], link: '/dvuhkamernye-steklopakety' }
    //     ]
    //   },
    //   // { name: 'Готовые решения', array: [], link: '/not-found' }
    // ]
  },
  {
    name: 'Балконы и лоджии',
    link: '/balkony'
    // array: [
    //   // { name: 'Окна и двери на балкон', array: [], link: '/not-found' },
    //   // { name: 'Утепление балкона', array: [], link: '/not-found' }
    // ]
  },
  {
    name: 'Аксесcуары',
    link: '/aksessuary'
  },
  // {
  //   name: 'Цены',
  //   array: [
  //     { name: 'Калькулятор окон', array: [], link: '/not-found' },
  //     { name: 'Пластиковые окна в рассрочку', array: [], link: '/not-found' }
  //   ]
  // },
  {
    name: 'О компании',
    array: [
      { name: 'О нас', array: [], link: '/o-kompanii' },
      { name: 'Наши работы', array: [], link: '/galery' },
      // { name: 'Вопрос-ответ', array: [], link: '/not-found' },
      { name: 'Доставка', array: [], link: '/dostavka' },
      { name: 'Установка', array: [], link: '/ustanovka' },
      { name: 'Сертификаты', array: [], link: '/o-nas' },
      { name: 'Производство', array: [], link: '/istoriya' },
      // { name: 'Отзывы', array: [], link: '/not-found' }
    ]
  },
  // {
  //   name: 'Диллерам'
  //   array: [],
  //   link: '/not-found'
  // },
  {
    name: 'Стать партнером',
    array: [
      { name: 'Общая информация', array: [], link: '/partneram' },
      { name: 'Стать диллером готовых окон', array: [], link: '/stat-dillerom' }
    ]
  },
  {
    name: 'Купить профиль',
    array: [],
    link: '/profiles'
  },
  {
    name: 'Где купить',
    array: [],
    link: '/not-found'
  },
  {
    name: 'Контакты',
    array: [],
    link: '/kontakty'
  }
];

export default menu;
